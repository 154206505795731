// Fields of siteManagementData
export const SITE_CODE_FIELD = 'siteCode';
export const ACCESS_POINT_ID = "accessPointId";
export const ADDRESS_ID_FIELD = 'addressId';
export const DEPLOYMENT_STATE_FIELD = 'deploymentState';
export const REGION_FIELD = 'region';
export const COUNTRY_FIELD = 'country';
export const BUSINESS_ENTITY_TYPE_FIELD = 'businessEntityType';
export const DA_LOADING_TYPE_FIELD = 'daLoadingType';
export const DP_LOADING_TYPE_FIELD = 'dpLoadingType';
export const PROPOSED_LAUNCH_DATE_FIELD = 'proposedLaunchDate';
export const ACTUAL_LAUNCH_DATE_FIELD = 'actualLaunchDate';
export const SITE_CONTACT_FIELD = 'siteContact';
export const REGION_CONTACT_FIELD = 'regionContact';
export const REMARK_FIELD = "remark";

// Fields of response
export const SITE_LIST_NAME = 'briefSiteManagementDataList';
export const SITE_OBJECT_NAME = 'siteManagementData';

// Contact message when the users fail to update or create siteInfo
export const CONTACT_MESSAGE_WHEN_FAILS
    = 'Please retry or contact IoT team: lastmile-iot-sparrow@amazon.com';
export const TERMINATE_CONFIRM_MESSAGE
    = 'Are you sure you want to terminate this site? This will remove all the Beacons from this site.';
export const REACTIVATE_CONFIRM_MESSAGE
    = 'Are you sure you want to reactivate this site?';

// Deployment States
export const TERMINATED = "TERMINATED";

// Change Site State actions
export const REACTIVATE = "REACTIVATE";
export const TERMINATE = "TERMINATE";

// Some other constants
export const LENGTH_OF_DATE_PORTION = 10;
export const TIME_OUT_IN_MS = 30 * 1000;
export const ACTION_FIELD = "action";
export const ADMIN_LDAP_GROUP = "BSMSPortal-admin";

// User Details
export const USERNAME = "username";