import {Form} from 'react-bootstrap';
import React from 'react';

const SearchBar = (props) => {
    let input;
    const handleChange = () => {
        props.onSearch(input.value);
    };

    return (
        <div>
            <Form.Control
                className="form-control"
                ref={(n) => {
                    input = n;
                }}
                type="text"
                placeholder="Enter Search Keywords"
                onChange={handleChange}
            />
        </div>
    );
};

export default SearchBar;
