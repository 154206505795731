import Amplify from 'aws-amplify';

let config = {};
const API_NAMES = {
    BSMSApi: 'BSMSApi',
};

async function loadAppConfig() {
    const baseUrl = window.location.origin;
    console.log('baseUrl=', baseUrl);
    config = await fetch(`${baseUrl}/config/config.json`).then((response) => response.json());
    console.log(`Loaded config=${JSON.stringify(config)}`);
    return config;
}

function getAppConfig() {
    return config;
}

function getAmplifyConfig() {
    const {
        region,
        userPoolId,
        userPoolWebClientId,
        apiConfig,
        identityPoolId,
        oauthConfig
    } = getAppConfig();

    const oauth = oauthConfig && {
        domain: oauthConfig.domain,
        scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
        redirectSignIn: window.location.protocol + '//' + window.location.host + '/',
        redirectSignOut: window.location.protocol + '//' + window.location.host + '/',
        responseType: 'code',
        options: {
            AdvancedSecurityDataCollectionFlag: false
        },
        label: 'Amazon Employee Sign in'
    };

    Amplify.configure({
        aws_cognito_region: region,
        Auth: {
            identityPoolRegion: region,
            userPoolId,
            userPoolWebClientId,
            mandatorySignIn: true,
            identityPoolId,
            oauth
        },
        API: {
            endpoints: Object.keys(API_NAMES).map((apiName) => ({
                name: API_NAMES[apiName],
                endpoint: apiConfig[API_NAMES[apiName]].endpoint
            }))
        }
    });
}

export {loadAppConfig, getAmplifyConfig, getAppConfig};