import React from "react";
import {Form} from 'react-bootstrap';

class DateRangeFilter extends React.Component {
    constructor(props) {
        super(props);
        this.fromDateRef = React.createRef();
        this.toDateRef = React.createRef();
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect() {
        const fromDate = new Date(this.fromDateRef.current.value);
        const toDate = new Date(this.toDateRef.current.value);
        const ranges = {
            fromDate: fromDate,
            toDate: toDate
        }
        this.props.onFilter(ranges);
    }

    render() {
        return (
            <div>
                From:{" "}<Form.Control ref={this.fromDateRef} type="date"
                                        onChange={this.handleSelect}/> {" "}
                To:{" "}<Form.Control ref={this.toDateRef} type="date"
                                      onChange={this.handleSelect}/>
            </div>
        );
    }
}

export default DateRangeFilter;
