import {Auth} from 'aws-amplify';
import {observable} from 'mobx';
// import * as constants from "../configs/constants";

const user = observable({
    isAdministrator: false
});

async function loadUserInfo() {
    try {
        const userInfo = await Auth.currentAuthenticatedUser({bypassCache: true});
        console.info("User info : ", userInfo);
        const groupList = userInfo.attributes['custom:ldap'] && JSON.parse(userInfo.attributes['custom:ldap']);
        console.log("User groups : ", groupList);
        // user.isAdministrator = groupList ? groupList.includes(constants.ADMIN_LDAP_GROUP) : false;
        user.isAdministrator = true // Allowing everyone admin rights for now.
        //Splitting userInfo.username based on _ as the format is midway_<username>
        user.username = userInfo.username.split("_")[1]
        console.info("isAdministrator : ", user.isAdministrator);
    } catch (e) {
        console.info("Exception : ", e);
        // This federatedSignIn() will cause automatic signing in after the user clicks sign out
        // (because the midway ticket is still valid). Comment enables users to login with Cognito.
        // await Auth.federatedSignIn({customProvider: 'Midway'});
    }
}

async function signOut() {
    user.isAdministrator = false;
    await Auth.signOut();
}

function getUser() {
    return user;
}

export {loadUserInfo, getUser, signOut};