import React from 'react';
import {Button, Form, Table} from "react-bootstrap";
import MenuBar from "./menu-bar";

import ApiClient from '../api/api-client';
import {ErrorMessage, Field, Formik} from "formik";
import options from "../configs/options.json";
import {validateRegionContactEmail, validateSiteContactEmail} from "../utils/validation-utils";
import * as constants from "../configs/constants";
import Loader from './loader';
import LoadingOverlay from "react-loading-overlay";
import {getUser} from '../stores/CurrentUserStore.js';

const loadingTypes = options.LOADING_TYPES.map(type => (
    <option key={type}>{type}</option>
));

function SiteDetailTable(props) {
    const status = props.status;
    const siteInfo = props.siteInfo;
    const errors = props.errors, touched = props.touched, handleChange = props.handleChange;

    const getDALoadingType = () => status === 'view' ? siteInfo[constants.DA_LOADING_TYPE_FIELD] :
        <Field as={"select"} name={constants.DA_LOADING_TYPE_FIELD} onChange={handleChange}
               className={"form-control"}>
            {loadingTypes}
        </Field>

    const getDPLoadingType = () => status === 'view' ? siteInfo[constants.DP_LOADING_TYPE_FIELD] :
        <Field as={"select"} name={constants.DP_LOADING_TYPE_FIELD} onChange={handleChange}
               className={"form-control"}>
            {loadingTypes}
        </Field>

    const getProposedLaunchDate = () => status === 'view' ? siteInfo[constants.PROPOSED_LAUNCH_DATE_FIELD] :
        <Field type="date" name={constants.PROPOSED_LAUNCH_DATE_FIELD} className={"form-control"}
               onChange={handleChange}/>

    const getSiteContactEmail = (errors, touched, handleChange) => status === 'view' ? siteInfo[constants.SITE_CONTACT_FIELD] :
        <div>
            <Field name={constants.SITE_CONTACT_FIELD}
                   className={'form-control' + (errors[constants.SITE_CONTACT_FIELD] && touched[constants.SITE_CONTACT_FIELD] ? ' is-invalid' : '')}
                   onChange={handleChange}/>
            <Form.Text className="text-muted">
                *Use commas to separate email addresses.
            </Form.Text>
            <ErrorMessage name={constants.SITE_CONTACT_FIELD} component="div"
                          className="invalid-feedback"/>
        </div>

    const getRegionContactEmail = (errors, touched, handleChange) => status === 'view' ? siteInfo[constants.REGION_CONTACT_FIELD] :
        <div>
            <Field name={constants.REGION_CONTACT_FIELD}
                   className={'form-control' + (errors[constants.REGION_CONTACT_FIELD] && touched[constants.REGION_CONTACT_FIELD] ? ' is-invalid' : '')}
                   onChange={handleChange}/>
            <ErrorMessage name={constants.REGION_CONTACT_FIELD} component="div"
                          className="invalid-feedback"/>
        </div>

    const getRemark = (errors, touched, handleChange) => {
        if (status === 'view') {
            return siteInfo[constants.REMARK_FIELD];
        } else {
            return (
                <div>
                    <Field name={constants.REMARK_FIELD} component="textarea" rows="3"
                           className={'form-control' + (errors[constants.REMARK_FIELD] && touched[constants.REMARK_FIELD] ? ' is-invalid' : '')}
                           onChange={handleChange}/>
                </div>
            );
        }
    }

    return (
        <Table striped bordered hover>
            <tbody>
            <tr>
                <td width="30%">Access Point Id</td>
                <td>{siteInfo[constants.ACCESS_POINT_ID]}</td>
            </tr>
            <tr>
                <td width="30%">Site Code</td>
                <td>{siteInfo[constants.SITE_CODE_FIELD]}</td>
            </tr>
            <tr>
                <td width="30%">Address Id</td>
                <td>{siteInfo[constants.ADDRESS_ID_FIELD]}</td>
            </tr>
            <tr>
                <td width="30%">Deployment State</td>
                <td>{siteInfo[constants.DEPLOYMENT_STATE_FIELD]}</td>
            </tr>
            <tr>
                <td width="30%">Region</td>
                <td>{siteInfo[constants.REGION_FIELD]}</td>
            </tr>
            <tr>
                <td width="30%">Country</td>
                <td>{siteInfo[constants.COUNTRY_FIELD]}</td>
            </tr>
            <tr>
                <td width="30%">Business Entity Type</td>
                <td>{siteInfo[constants.BUSINESS_ENTITY_TYPE_FIELD]}</td>
            </tr>
            <tr>
                <td width="30%">DA Loading Type</td>
                <td>{getDALoadingType()}</td>
            </tr>
            <tr>
                <td width="30%">DP Loading Type</td>
                <td>{getDPLoadingType()}</td>
            </tr>
            <tr>
                <td width="30%">Proposed Launch Date</td>
                <td>{getProposedLaunchDate()}</td>
            </tr>
            <tr>
                <td width="30%">Actual Launch Date</td>
                <td>{siteInfo[constants.ACTUAL_LAUNCH_DATE_FIELD]}</td>
            </tr>
            <tr>
                <td width="30%">Site Contact Email</td>
                <td>{getSiteContactEmail(errors, touched, handleChange)}
                </td>
            </tr>
            <tr>
                <td width="30%">Region Contact Email</td>
                <td>{getRegionContactEmail(errors, touched, handleChange)}</td>
            </tr>
            <tr>
                <td width="30%">Remark</td>
                <td style={{whiteSpace: 'pre-wrap'}}>{getRemark(errors, touched, handleChange)}</td>
            </tr>
            </tbody>
        </Table>
    );
}

class Site extends React.Component {
    state = {
        status: 'view',
        siteInfo: null,
        isLoading: false
    };

    handleEdit = () => {
        this.setState({status: 'edit'});
    }

    handleSubmit = async (fields) => {
        Object.keys(fields).map(k =>
            fields[k] = typeof fields[k] == 'string' ? fields[k].trim() : fields[k]);

        try {
            fields[constants.ACCESS_POINT_ID] = this.state.siteInfo[constants.ACCESS_POINT_ID];
            fields[constants.SITE_CODE_FIELD] = this.state.siteInfo[constants.SITE_CODE_FIELD];
            this.setState({isLoading: true});
            await ApiClient.updateSite(fields);
        } catch (e) {
            ApiClient.handleException(e);
        } finally {
            this.setState({isLoading: false});
        }

        this.setState({
            siteInfo: null,
            status: 'view'
        });
        this.loadData();
    };

    handleCancel = () => {
        this.setState({status: 'view'});
    }

    handleReactivate = async () => {
        if (window.confirm(constants.REACTIVATE_CONFIRM_MESSAGE)) {
            try {
                this.setState({isLoading: true});
                await ApiClient.reactivate(this.state.siteInfo[constants.ACCESS_POINT_ID]);
            } catch (e) {
                ApiClient.handleException(e);
            } finally {
                this.loadData();
                this.setState({isLoading: false});
            }
        }
    }

    handleTerminate = async () => {
        if (window.confirm(constants.TERMINATE_CONFIRM_MESSAGE)) {
            try {
                this.setState({isLoading: true});
                await ApiClient.terminate(this.state.siteInfo[constants.ACCESS_POINT_ID]);
            } catch (e) {
                ApiClient.handleException(e);
            } finally {
                this.loadData();
                this.setState({isLoading: false});
            }
        }
    }

    async loadData() {
        try {
            const siteInfo = await ApiClient.getSiteDetail(this.props.match.params.accessPointId);
            this.setState({
                siteInfo: siteInfo,
            });
        } catch (e) {
            ApiClient.handleException(e);
        }
    }

    componentDidMount() {
        this.loadData();
    }

    validateForm = (form) => {
        const errors = {};
        validateSiteContactEmail(form[constants.SITE_CONTACT_FIELD], errors);
        validateRegionContactEmail(form[constants.REGION_CONTACT_FIELD], errors);
    }

    getButtons = () => {
        if (!getUser().isAdministrator) {
            return null;
        }
        let changeStateButton;
        if (this.state.siteInfo[constants.DEPLOYMENT_STATE_FIELD] === constants.TERMINATED) {
            changeStateButton = <Button className="ml-auto" variant="primary"
                                        onClick={this.handleReactivate}> Reactivate </Button>;
        } else {
            changeStateButton = <Button className="ml-auto" variant="primary"
                                        onClick={this.handleTerminate}> Terminate </Button>;
        }

        return this.state.status === 'view' ?
            <div>
                <Button className="ml-auto" variant="primary"
                        onClick={this.handleEdit}> Edit </Button>
                {" "}{changeStateButton}

            </div>
            : <div>
                <Button type={"submit"}> Submit </Button>{" "}
                <Button className="ml-auto" variant="primary"
                        onClick={this.handleCancel}> Cancel </Button>{" "}
            </div>
    }

    render() {
        const siteInfo = this.state.siteInfo;
        let siteDetailTable;

        if (siteInfo === null) {
            siteDetailTable = (<Loader/>);
        } else if (this.state.status === 'view') {
            siteDetailTable = (
                <div>
                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner
                        text="Submitting..."
                    >
                        <SiteDetailTable status={this.state.status}
                                         siteInfo={this.state.siteInfo}/>
                        {this.getButtons()}
                    </LoadingOverlay>
                </div>
            )
        } else {
            siteDetailTable = (
                <div>
                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner
                        text="Submitting..."
                    >
                        <Formik
                            initialValues={{
                                [constants.DA_LOADING_TYPE_FIELD]: siteInfo[constants.DA_LOADING_TYPE_FIELD],
                                [constants.DP_LOADING_TYPE_FIELD]: siteInfo[constants.DP_LOADING_TYPE_FIELD],
                                [constants.PROPOSED_LAUNCH_DATE_FIELD]: new Date(siteInfo[constants.PROPOSED_LAUNCH_DATE_FIELD]).toISOString().slice(0, constants.LENGTH_OF_DATE_PORTION),
                                [constants.SITE_CONTACT_FIELD]: siteInfo[constants.SITE_CONTACT_FIELD],
                                [constants.REGION_CONTACT_FIELD]: siteInfo[constants.REGION_CONTACT_FIELD],
                                [constants.REMARK_FIELD]: siteInfo[constants.REMARK_FIELD] === null ? '' : siteInfo[constants.REMARK_FIELD]
                            }}
                            validate={this.validateForm}
                            onSubmit={this.handleSubmit}
                        >
                            {({handleChange, errors, touched, handleSubmit}) => (
                                <Form onSubmit={handleSubmit}>
                                    <SiteDetailTable status={this.state.status}
                                                     siteInfo={siteInfo}
                                                     errors={errors}
                                                     handleChange={handleChange}
                                                     touched={touched}/>
                                    {this.getButtons()}
                                </Form>
                            )}
                        </Formik>
                    </LoadingOverlay>
                </div>
            );
        }

        return (
            <div>
                <MenuBar/>
                {siteDetailTable}
            </div>
        )
    }
}

export default Site;
