import React from 'react';
import {RequireNewPassword, SignIn, VerifyContact, withAuthenticator} from 'aws-amplify-react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import Overview from "./overview";
import Site from "./site";
import CreateForm from "./create-form"

class App extends React.Component {
    render() {
        return (
            <Container>
                <BrowserRouter>
                    <Switch>
                        <Route path='/create' exact component={CreateForm}/>
                        <Route path='/site/:accessPointId' exact component={Site}/>
                        <Route component={Overview}/>
                    </Switch>
                </BrowserRouter>
            </Container>
        );
    }
}

export default withAuthenticator(App, false, [<SignIn/>, <VerifyContact/>, <RequireNewPassword/>]);
