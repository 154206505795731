import React from 'react';
import {Spinner} from 'react-bootstrap';

class Loader extends React.Component {
    render() {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status" size={this.props.size}>
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }
}

export default Loader;
