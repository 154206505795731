import React from 'react';
import {Button} from "react-bootstrap";
import {Link} from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {customFilter, selectFilter} from 'react-bootstrap-table2-filter';
import LoadingOverlay from "react-loading-overlay";

import ApiClient from '../api/api-client';
import MenuBar from "./menu-bar";
import SearchBar from "./search-bar";
import options from '../configs/options.json';
import DateRangeFilter from './date-range-filter';
import * as constants from "../configs/constants";
import Loader from "./loader";
import {getUser} from '../stores/CurrentUserStore.js';

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

function mapToSelectOptions(options) {
    options.sort();
    return options.map((v) => {
        return {
            label: v,
            value: v
        }
    });
}

function compareTwoOptionLists(optionList1, optionList2) {
    return optionList1.length === optionList2.length
        && optionList1.every(function (value, index) {
            return value.value === optionList2[index].value
        });
}

const regionOptions = mapToSelectOptions(options.REGIONS);
const deploymentStateOptions = mapToSelectOptions(options.STATES);

class Overview extends React.Component {
    state = {
        siteList: null,
        siteCodeSet: null,
        isLoading: false,
        countryOptions: [],
        businessEntityTypeOptions: []
    }

    countryFilter;
    allCountryOptionsFromSites = [];
    countryOptionsUpdated = false;

    dateRangeFilter = (ranges, data) => {
        // The ranges will be reused so we shouldn't modify it directly
        const toDate = new Date(ranges.toDate);
        // Because new Date() will create date in local time zone
        // Date created from date picker is in GMT+0 and transformed to local time zone
        // For example, selecting May 6 in date picker will cause May 5 19:00 in GMT-5 zone
        if (isValidDate(ranges.toDate)) {
            toDate.setDate(ranges.toDate.getDate() + 1);
        }
        return data
            .filter((site) => {
                    const targetDate = new Date(site[constants.PROPOSED_LAUNCH_DATE_FIELD]);
                    return ((isValidDate(ranges.fromDate) && targetDate >= ranges.fromDate) || !isValidDate(ranges.fromDate))
                        && ((isValidDate(toDate) && targetDate <= toDate) || !isValidDate(toDate));
                }
            );
    };

    changeCountryOptionsForRegion = (regionValue) => {
        // Compute all possible country options for current region
        let newCountryOptions;
        if (regionValue) {
            const allCountriesInRegion = new Set(options.REGION_TO_COUNTRY[regionValue]);
            newCountryOptions
                = this.allCountryOptionsFromSites.filter(option => allCountriesInRegion.has(option.value));
        } else {
            newCountryOptions = this.allCountryOptionsFromSites;
        }
        // If they're different, setState to re-render
        if (!compareTwoOptionLists(this.state.countryOptions, newCountryOptions)) {
            this.setState({
                countryOptions: newCountryOptions
            });
            // Note: the update of country options needs an extra render to take effect,
            // we set countryOptionsUpdated which is used in componentDidUpdate()
            // to achieve this
            this.countryOptionsUpdated = true;
        }
    }

    defaultSorted = [{
        dataField: constants.SITE_CODE_FIELD,
        order: 'asc'
    }];

    siteCodeFieldFormatter(cell, row) {
        return (
            <div>
                <Link to={'/site/' + row[constants.ACCESS_POINT_ID]}>{cell}</Link>
            </div>
        );
    };

    setFilterOptionsAndSiteCodes(siteList) {
        const countrySet = new Set();
        const businessEntityTypeSet = new Set();
        const siteCodeSet = new Set();

        for (const site of siteList) {
            countrySet.add(site[constants.COUNTRY_FIELD]);
            businessEntityTypeSet.add(site[constants.BUSINESS_ENTITY_TYPE_FIELD]);
            siteCodeSet.add(site[constants.SITE_CODE_FIELD]);
        }

        this.allCountryOptionsFromSites = mapToSelectOptions([...countrySet]);
        const businessEntityTypeOptions = mapToSelectOptions([...businessEntityTypeSet]);

        this.setState({
            siteCodeSet: siteCodeSet,
            countryOptions: this.allCountryOptionsFromSites,
            businessEntityTypeOptions: businessEntityTypeOptions,
            isLoading: false,
            siteList: siteList
        });
    }

    async loadData() {
        this.setState({isLoading: true});
        try {
            const siteList = await ApiClient.getSiteList();
            this.setFilterOptionsAndSiteCodes(siteList);
        } catch (e) {
            ApiClient.handleException(e);
        }
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        // If the country options is updated, reset country options filter,
        // and this action will cause a re-render, which is desired
        if (this.countryOptionsUpdated) {
            this.countryOptionsUpdated = false;
            this.countryFilter('');
        }
    }

    handleReload = () => {
        this.loadData();
    }

    render() {
        const customTotal = (fromRecordNum, toRecordNum, sizePerPage) => (
            <span className="react-bootstrap-table-pagination-total">
                Showing {fromRecordNum} to {toRecordNum} of {sizePerPage} entries(Filtered from {this.state.siteList.length} entries)
            </span>
        );

        const paginationOptions = {
            paginationSize: 5,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '20', value: 20
            }]
        };

        const columns = [{
            dataField: constants.ACCESS_POINT_ID,
            text: 'Access Point Id',
            hidden: true,
            sort: true
        }, {
            dataField: constants.SITE_CODE_FIELD,
            text: 'Site',
            formatter: this.siteCodeFieldFormatter,
            sort: true
        }, {
            dataField: constants.DEPLOYMENT_STATE_FIELD,
            text: 'Deployment State',
            sort: true,
            filter: selectFilter({
                placeholder: "All",
                options: deploymentStateOptions
            })
        }, {
            dataField: constants.REGION_FIELD,
            text: 'Region',
            sort: true,
            filter: selectFilter({
                placeholder: "All",
                options: regionOptions,
                onFilter: this.changeCountryOptionsForRegion
            })
        }, {
            dataField: constants.COUNTRY_FIELD,
            text: 'Country',
            sort: true,
            filter: selectFilter({
                placeholder: "All",
                options: this.state.countryOptions,
                getFilter: (filter) => {
                    this.countryFilter = filter;
                },
            })
        }, {
            dataField: constants.BUSINESS_ENTITY_TYPE_FIELD,
            text: 'Business Type',
            sort: true,
            filter: selectFilter({
                placeholder: "All",
                options: this.state.businessEntityTypeOptions
            })
        }, {
            dataField: constants.PROPOSED_LAUNCH_DATE_FIELD,
            text: 'Proposed Launch Date',
            sort: true,
            filter: customFilter({onFilter: this.dateRangeFilter}),
            filterRenderer: (onFilter, column) =>
                <DateRangeFilter onFilter={onFilter} column={column}/>
        }];

        let sitesOverview = (<Loader/>);
        if (this.state.siteList) {
            sitesOverview = (
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner
                    text="Loading data..."
                >
                    <ToolkitProvider
                        bootstrap4 striped bordered hover
                        keyField={constants.SITE_CODE_FIELD}
                        data={this.state.siteList}
                        columns={columns}
                        search
                    >
                        {
                            (props) => (
                                <div>
                                    <SearchBar {...props.searchProps} />
                                    <BootstrapTable defaultSorted={this.defaultSorted}
                                                    pagination={paginationFactory(paginationOptions)}
                                                    filter={filterFactory()}
                                                    filterPosition="top"
                                                    {...props.baseProps}/>
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </LoadingOverlay>
            );
        }

        let editButtons = <Button className="ml-auto" variant="primary" onClick={this.handleReload}>Click to
            Reload</Button>;
        if (getUser().isAdministrator) {
            editButtons =
                <div>
                    <Link to={{
                        pathname: '/create',
                        siteCodeSet: this.state.siteCodeSet
                    }}>
                        <Button className="ml-auto" variant="primary">Create</Button>{' '}
                    </Link>
                    <Button className="ml-auto" variant="primary" onClick={this.handleReload}>Click to Reload</Button>
                </div>
        }

        return (
            <div>
                <MenuBar/>
                {sitesOverview}
                {editButtons}
            </div>
        );
    }
}

export default Overview;
