import React from 'react';
import {Button, Nav, Navbar} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {signOut} from '../stores/CurrentUserStore.js';

class MenuBar extends React.Component {

    render() {
        return (
            <Navbar bg="dark" variant="dark" expand="lg">
                <Navbar.Brand as={Link} to='/'>Beacon Site Management Portal</Navbar.Brand>
                <Nav className="ml-auto">
                    <Button variant="outline-success" size="sm" onClick={() => signOut()}>Sign Out</Button>
                </Nav>
            </Navbar>
        );
    }
}

export default MenuBar;