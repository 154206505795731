import * as constants from "../configs/constants";

export function validateSiteContactEmail(siteContactEmail, errors) {
   if (siteContactEmail) {
       const emails = siteContactEmail.split(',');
       for (const email of emails) {
           if (!email
               || !email.trim()
               || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim())) {
               errors[constants.SITE_CONTACT_FIELD] = 'One or more Site Contact Emails are invalid!';
               break;
           }
       }
   }
}

export function validateRegionContactEmail(regionContactEmail, errors) {
    if (regionContactEmail
        && regionContactEmail.trim()
        && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(regionContactEmail.trim())) {
        errors[constants.REGION_CONTACT_FIELD] = 'Region Contact Email is invalid!';
    }
}