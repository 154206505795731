import axios from 'axios'
import * as constants from "../configs/constants";
import {API, Auth, Signer} from 'aws-amplify';
import {getUser} from '../stores/CurrentUserStore.js';

async function getConfig(method, apiName, path, awsAuth, payload) {
    const endpoint = await API.endpoint(apiName);
    let signedRequest;
    if (awsAuth === 'awsv4') {
        const credential = await Auth.currentCredentials();
        signedRequest = Signer.sign({
            method: method,
            url: endpoint + path,
            headers: {},
            data: payload && JSON.stringify(payload)
        }, {
            // eslint-disable-next-line camelcase
            secret_key: credential.secretAccessKey,
            // eslint-disable-next-line camelcase
            access_key: credential.accessKeyId,
            // eslint-disable-next-line camelcase
            session_token: credential.sessionToken
        });
    } else if (awsAuth === 'cognito') {
        signedRequest = {
            headers: {Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
        };
    }

    const config = {
        timeout: constants.TIME_OUT_IN_MS
    }
    if (signedRequest) {
        config.headers = signedRequest.headers;
    }

    return config;
}

async function get(apiName, path, awsAuth) {
    const endpoint = await API.endpoint(apiName);
    return await axios.get(endpoint + path, await getConfig('GET', apiName, path, awsAuth));
}

async function post(apiName, path, awsAuth, payload) {
    const endpoint = await API.endpoint(apiName);
    return await axios.post(endpoint + path, payload, await getConfig('POST', apiName, path, awsAuth, payload));
}

async function put(apiName, path, awsAuth, payload) {
    const endpoint = await API.endpoint(apiName);
    return await axios.put(endpoint + path, payload, await getConfig('PUT', apiName, path, awsAuth, payload));
}

class ApiClient {
    static async getSiteList() {
        const response = await get("BSMSApi", '/bsms/siteinfo', 'awsv4')
        console.info(response);
        return response.data[constants.SITE_LIST_NAME];
    }

    static async getSiteDetail(accessPointId) {
        const response = await get("BSMSApi", '/bsms/siteinfo/' + accessPointId, 'awsv4');
        console.info(response);
        return await response.data[constants.SITE_OBJECT_NAME];
    }

    static async createSite(siteInfo) {
        const body = {
            [constants.USERNAME]: getUser().username,
            [constants.SITE_OBJECT_NAME]: siteInfo
        };
        const response = await post("BSMSApi", '/bsms/siteinfo', 'awsv4', body);
        console.info(response);
        window.location.href = "/";
    }

    static async updateSite(siteInfo) {
        const body = {
            [constants.USERNAME]: getUser().username,
            [constants.SITE_OBJECT_NAME]: siteInfo
        };
        const response = await put("BSMSApi", '/bsms/siteinfo', 'awsv4', body);
        console.info(response);
    }

    static async reactivate(accessPointId) {
        const body = {
            [constants.USERNAME]: getUser().username,
            [constants.ACCESS_POINT_ID]: accessPointId,
            [constants.ACTION_FIELD]: constants.REACTIVATE
        };
        const response = await post("BSMSApi", '/bsms/changesitestate', 'awsv4', body);
        console.info(response);
    }

    static async terminate(accessPointId) {
        const body = {
            [constants.USERNAME]: getUser().username,
            [constants.ACCESS_POINT_ID]: accessPointId,
            [constants.ACTION_FIELD]: constants.TERMINATE
        };
        const response = await post("BSMSApi", '/bsms/changesitestate', 'awsv4', body);
        console.info(response);
    }

    static handleException = (error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.data.message) {
                alert(error.response.data.message
                    + '\n' + constants.CONTACT_MESSAGE_WHEN_FAILS);
            } else {
                alert(JSON.stringify(error.response.data)
                    + '\n' + constants.CONTACT_MESSAGE_WHEN_FAILS);
            }
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            alert(JSON.stringify(error.request)
                + '\n' + constants.CONTACT_MESSAGE_WHEN_FAILS);
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            alert(JSON.stringify(error.message)
                + '\n' + constants.CONTACT_MESSAGE_WHEN_FAILS);
            console.log(error.message);
        }
        console.log(error.request);
        console.log(error.config);
    }
}

export default ApiClient;
