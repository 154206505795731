import {Button, Col, Form, Row} from 'react-bootstrap';
import React from "react";

import ApiClient from '../api/api-client';
import MenuBar from "./menu-bar";
import {Link} from 'react-router-dom';
import {ErrorMessage, Field, Formik} from 'formik';
import options from '../configs/options.json'
import {validateRegionContactEmail, validateSiteContactEmail} from "../utils/validation-utils";
import * as constants from "../configs/constants";
import LoadingOverlay from "react-loading-overlay";
import {getUser} from '../stores/CurrentUserStore.js';

const loadingTypes = options.LOADING_TYPES.map(type => (
    <option key={type}>{type}</option>
));

const regions = options.REGIONS.map(type => (
    <option key={type}>{type}</option>
));

const businessEntityTypes = options.BUSINESS_ENTITY_TYPES.map(type => (
    <option key={type}>{type}</option>
));

class CreateForm extends React.Component {
    state = {
        isLoading: false
    }

    validateForm = (form) => {
        const errors = {};
        if (!form[constants.SITE_CODE_FIELD] || !form[constants.SITE_CODE_FIELD].trim()) {
            errors[constants.SITE_CODE_FIELD] = 'Site Code is required!';
        } else if (!/^[a-zA-Z0-9]{4}$/.test(form[constants.SITE_CODE_FIELD].trim())) {
            errors[constants.SITE_CODE_FIELD] = 'Invalid Site Code.';
        } else if (this.props.location.siteCodeSet
            && this.props.location.siteCodeSet.has(form[constants.SITE_CODE_FIELD].trim())) {
            errors[constants.SITE_CODE_FIELD] = 'Site already exists.';
        }

        if (!form[constants.COUNTRY_FIELD]) {
            errors[constants.COUNTRY_FIELD] = 'Country Code is required!';
        } else if (form[constants.COUNTRY_FIELD]
            && form[constants.COUNTRY_FIELD].trim()
            && !/^[a-zA-Z]{2}$/.test(form[constants.COUNTRY_FIELD].trim())) {
            errors[constants.COUNTRY_FIELD] = 'Country Code is invalid!';
        }

        validateSiteContactEmail(form[constants.SITE_CONTACT_FIELD], errors);
        validateRegionContactEmail(form[constants.REGION_CONTACT_FIELD], errors);
        return errors;
    }

    handleSubmit = async (fields) => {
        Object.keys(fields).map(k =>
            fields[k] = typeof fields[k] == 'string' ? fields[k].trim() : fields[k]);

        try {
            this.setState({isLoading: true});
            await ApiClient.createSite(fields);
        } catch (e) {
            ApiClient.handleException(e);
        } finally {
            this.setState({isLoading: false});
        }
    };

    render() {
        if (getUser().isAdministrator) {
            return (
                <div>
                    <MenuBar/>
                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner
                        text="Submitting..."
                    >
                        <Formik
                            initialValues={{
                                [constants.SITE_CODE_FIELD]: '',
                                [constants.REGION_FIELD]: 'NA',
                                [constants.COUNTRY_FIELD]: '',
                                [constants.DA_LOADING_TYPE_FIELD]: 'INSIDE_LOADING',
                                [constants.DP_LOADING_TYPE_FIELD]: 'INSIDE_LOADING',
                                [constants.BUSINESS_ENTITY_TYPE_FIELD]: 'AMZL OPS',
                                [constants.PROPOSED_LAUNCH_DATE_FIELD]: new Date().toISOString().slice(0, constants.LENGTH_OF_DATE_PORTION),
                                [constants.SITE_CONTACT_FIELD]: '',
                                [constants.REGION_CONTACT_FIELD]: '',
                                [constants.REMARK_FIELD]: ''
                            }}
                            validate={this.validateForm}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  handleChange, errors,
                                  touched, handleSubmit,
                                  values
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">Site Code</Form.Label>
                                        <Col sm="9">
                                            <Field name={constants.SITE_CODE_FIELD}
                                                   className={'form-control' + (errors[constants.SITE_CODE_FIELD] && touched[constants.SITE_CODE_FIELD] ? ' is-invalid' : '')}
                                                   onChange={handleChange}
                                                   placeholder={"Enter Site Code"}/>
                                            <ErrorMessage name={constants.SITE_CODE_FIELD} component="div"
                                                          className="invalid-feedback"/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">Region</Form.Label>
                                        <Col sm="9">
                                            <Field as={"select"} name={constants.REGION_FIELD} onChange={handleChange}
                                                   className={"form-control"} value={values[constants.REGION_FIELD]}>
                                                {regions}
                                            </Field>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">Country</Form.Label>
                                        <Col sm="9">
                                            <Field name={constants.COUNTRY_FIELD}
                                                   className={'form-control' + (errors[constants.COUNTRY_FIELD] && touched[constants.COUNTRY_FIELD] ? ' is-invalid' : '')}
                                                   onChange={handleChange}
                                                   placeholder={"Enter Country Code"}/>
                                            <ErrorMessage name={constants.COUNTRY_FIELD} component="div"
                                                          className="invalid-feedback"/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">DA Loading Type</Form.Label>
                                        <Col sm="9">
                                            <Field as={"select"} name={constants.DA_LOADING_TYPE_FIELD}
                                                   onChange={handleChange}
                                                   className={"form-control"}
                                                   value={values[constants.DA_LOADING_TYPE_FIELD]}>
                                                {loadingTypes}
                                            </Field>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">DP Loading Type</Form.Label>
                                        <Col sm="9">
                                            <Field as={"select"} name={constants.DP_LOADING_TYPE_FIELD}
                                                   onChange={handleChange}
                                                   className={"form-control"}
                                                   value={values[constants.DP_LOADING_TYPE_FIELD]}>
                                                {loadingTypes}
                                            </Field>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">Business Entity Type</Form.Label>
                                        <Col sm="9">
                                            <Field as={"select"} name={constants.BUSINESS_ENTITY_TYPE_FIELD}
                                                   onChange={handleChange}
                                                   className={"form-control"}
                                                   value={values[constants.BUSINESS_ENTITY_TYPE_FIELD]}>
                                                {businessEntityTypes}
                                            </Field>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">Proposed Launch Date</Form.Label>
                                        <Col sm="9">
                                            <Field type="date" name={constants.PROPOSED_LAUNCH_DATE_FIELD}
                                                   className={"form-control"}
                                                   onChange={handleChange}/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">Site Contact Email</Form.Label>
                                        <Col sm="9">
                                            <Field name={constants.SITE_CONTACT_FIELD}
                                                   className={'form-control' + (errors[constants.SITE_CONTACT_FIELD] && touched[constants.SITE_CONTACT_FIELD] ? ' is-invalid' : '')}
                                                   onChange={handleChange}
                                                   placeholder={"Enter Site Contact Email."}/>
                                            <Form.Text className="text-muted">
                                                *Use commas to separate email addresses.
                                            </Form.Text>
                                            <ErrorMessage name={constants.SITE_CONTACT_FIELD} component="div"
                                                          className="invalid-feedback"/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">Region Contact Email</Form.Label>
                                        <Col sm="9">
                                            <Field name={constants.REGION_CONTACT_FIELD}
                                                   className={'form-control'}
                                                   onChange={handleChange}
                                                   placeholder={"Enter Region Contact Email."}/>
                                            <ErrorMessage name={constants.REGION_CONTACT_FIELD} component="div"
                                                          className="invalid-feedback"/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">Remark</Form.Label>
                                        <Col sm="9">
                                            <Field name={constants.REMARK_FIELD} component="textarea" rows="3"
                                                   className={'form-control'}
                                                   onChange={handleChange}/>
                                        </Col>
                                    </Form.Group>

                                    <Button type="submit">Create Site</Button>{" "}
                                    <Link to="/">
                                        <Button type="button">Cancel</Button>
                                    </Link>
                                </Form>)}
                        </Formik>
                    </LoadingOverlay>
                </div>
            );
        } else {
            return (
                <div>Access Denied</div>
            )
        }
    }
}

export default CreateForm